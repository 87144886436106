<template>
    <v-app>
        <v-app-bar dark absolute color="indigo darken-4">

            <h3>AUDIDOC</h3>
            
        </v-app-bar>
        <v-main class="mt-15">
            <BuscarEstadoDocumentos :token="$route.params.id" />
        </v-main>
    </v-app>
</template>
<script>
import BuscarEstadoDocumentos from '../components/comunes/BuscarEstadoDocumentos.vue'

export default {
    components: {
        BuscarEstadoDocumentos
    },
    data(){
        return {

        }
    },
    created(){
        console.log(this.$route.params)
        
    }
}
</script>